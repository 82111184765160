<template>
  <div class="">
    <div class="flex flex-row items-center justify-between px-4 py-4">
      <span class="font-medium">{{$tc('channel.posts.count', postsCount)}}</span>
      <div class="flex flex-row">
        <label
          role="radio"
          class="button button_icon"
          :class="{'button_type_filled': view === 'list'}">
          <input type="radio" class="input_hidden" v-model="view" value="list"/>
          <i class="material-icons">view_agenda</i>
        </label>
        <label
          role="radio"
          class="button button_icon"
          :class="{'button_type_filled': view === 'table'}">
          <input type="radio" class="input_hidden" v-model="view" value="table"/>
          <i class="material-icons">view_headline</i>
        </label>
      </div>
    </div>
    <div
      class="bg-gray-200 rounded p-4 pb-0 flex flex-col max-w-xl"
      v-if="view === 'list'">
      <div v-for="post in posts" class="mb-4">
        <div class="flex flex-row items-center mb-1">
            <span class="font-medium">
              <el-icon name="check" v-if="post.status === 'sent'"/>
              <el-icon name="timer" v-else-if="post.status === 'created'"/>
              {{formatDate(new Date(post.date || 0))}}
            </span>

          <div class="spacer"/>

          <router-link
            class="button button_icon button_size_xs"
            :to="`/post/${post.id}`"
            :title="$t('channel.posts.actions.open')">
            <OpenIcon class="icon_size_sm"/>
          </router-link>
          <router-link
            class="button button_icon button_size_xs ml-1"
            :to="`/edit/${post.id}`"
            :title="$t('channel.posts.actions.edit')">
            <EditIcon class="icon_size_sm"/>
          </router-link>
          <router-link
            class="button button_icon button_size_xs ml-1"
            :to="`/stats/${post.id}`"
            :title="$t('channel.posts.actions.stats')"
            v-if="post.status === 'sent'">
            <StatsIcon class="icon_size_sm"/>
          </router-link>
        </div>
        <PostAppearance :post="post"/>
      </div>
    </div>
    <el-table
      v-else-if="view === 'table'"
      class="channel__posts-table"
      :empty-text="$t('channel.posts.noPosts')"
      :data="postData"
      :default-sort="{prop: 'date', order: 'descending'}">
      <el-table-column
        prop="date"
        :label="$t('channel.posts.labels.date')"
        :formatter="dateFormatter"
        sortable
        width="150">
        <template slot-scope="{row: post}">
          {{formatDate(post.date || 0)}}
        </template>
      </el-table-column>
      <!--<el-table-column
        prop="id"
        label="ID"
        sortable
        width="200">
        <template slot-scope="{row}">
          <code class="id-code">{{row.id}}</code>
        </template>
      </el-table-column>-->
      <!--      <el-table-column-->
      <!--        prop="created_at"-->
      <!--        :label="$t('channel.posts.labels.created')"-->
      <!--        :formatter="createdAtFormatter"-->
      <!--        width="150"-->
      <!--        sortable/>-->
      <!--      <el-table-column-->
      <!--        prop="type"-->
      <!--        :label="$t('channel.posts.labels.type')"-->
      <!--        sortable-->
      <!--        width="150">-->
      <!--        <template slot-scope="{row: post}">-->
      <!--          <template v-if="post.type === 'scheduled'">-->
      <!--            <el-icon name="timer"/>-->
      <!--            {{$t('channel.posts.types.scheduled')}}-->
      <!--          </template>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column
        prop="status"
        :label="$t('channel.posts.labels.status')"
        sortable>
        <template slot-scope="{row: post}">
          <template v-if="post.status === 'sent'">
            <el-icon name="check" class="mr-2"/>
            {{$t('post.statuses.sent')}}
          </template>
          <template v-else-if="post.status === 'created'">
            <el-icon name="timer" class="mr-2"/>
            {{$t('post.statuses.scheduled')}}
          </template>
        </template>
      </el-table-column>
      <!--      <el-table-column-->
      <!--        prop="status"-->
      <!--        label="Open"-->
      <!--        sortable>-->
      <!--        <template slot-scope="{row: post}">-->
      <!--          <template v-if="post.is_closed">-->
      <!--            <el-icon name="close"/> No-->
      <!--          </template>-->
      <!--          <template v-else>-->
      <!--            <el-icon name="check"/> Yes-->
      <!--          </template>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column
        :label="$t('channel.posts.labels.actions')"
        width="180">
        <template slot-scope="{row: post}">
          <el-popover trigger="hover" placement="left-start">
            <button
              class="button button_icon"
              :title="$t('channel.posts.actions.preview')"
              slot="reference">
              <PreviewIcon/>
            </button>
            <PostAppearance :post="post" class="max-w-xl"/>
          </el-popover>
          <router-link
            class="button button_icon"
            :to="`/post/${post.id}`"
            :title="$t('channel.posts.actions.open')">
            <OpenIcon/>
          </router-link>
          <router-link
            class="button button_icon"
            :to="`/edit/${post.id}`"
            :title="$t('channel.posts.actions.edit')">
            <EditIcon/>
          </router-link>
          <router-link
            class="button button_icon"
            :to="`/stats/${post.id}`"
            :title="$t('channel.posts.actions.stats')"
            v-if="post.status === 'sent'">
            <StatsIcon/>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <button
      v-if="posts.length !== postsCount"
      class="button mt-2"
      :class="{
        'button_loading': loadMoreButtonLoading,
      }"
      @click="loadMore">
      {{$t('channel.posts.loadMore')}}
    </button>
  </div>
</template>

<script>
  import {
    format,
    utcToZonedTime,
  } from 'date-fns-tz'
  // import Masonry from 'vue-masonry-css'

  import PostAppearance from '@/components/PostAppearance.vue'

  import PreviewIcon from '@/assets/icons/visibility-24px.svg'
  import OpenIcon from '@/assets/icons/open_in_new-24px.svg'
  import EditIcon from '@/assets/icons/edit-24px.svg'
  import StatsIcon from '@/assets/icons/show_chart-24px.svg'

  import dateFnsLocaleMixin from '@/mixins/dateFnsLocale'
  import {nextTick} from '@/mixins/utils'

  import {getPostsByCid} from '@/functions/data'
  import {chain} from '@/functions/utils'

  import store from '@/store'
  import {apiGetPostsByCid} from '@/functions/api'

  const CHUNK_SIZE = 20

  export default {
    name: 'ChannelPosts',
    mixins: [
      dateFnsLocaleMixin,
      nextTick,
    ],
    components: {
      // Masonry,

      PostAppearance,

      PreviewIcon,
      OpenIcon,
      EditIcon,
      StatsIcon,
    },
    props: {
      channel: Object,
    },
    data: () => ({
      postsCount: 0,
      posts: [],

      views: ['list', 'table'],
      view: 'list',

      limit: 20,

      loadMoreButtonLoading: false,
    }),
    methods: {
      applyPostsResult(result) {
        this.postsCount = result.total_count
        this.posts = result.posts
        // for (let i = 0; i < 100; ++i) {
        //   this.posts.push(...result.posts)
        // }
      },

      createdAtFormatter(post) {
        return this.formatDate(new Date(post.created_at || 0))
      },
      dateFormatter(post) {
        if (post.type !== 'scheduled') {
          return ''
        }
        return this.formatDate(new Date(post.date || 0))
      },

      formatDate(date) {
        const timeZone = this.channelTimezone
        return format(
          utcToZonedTime(date, timeZone),
          this.$t('common.dateTimeLong'),
          {locale: this.dateFnsLocale, timeZone},
        )
      },

      async loadMore() {
        this.loadMoreButtonLoading = true

        const offset = this.posts.length

        const result = await apiGetPostsByCid({
          cid: this.cid,
          limit: CHUNK_SIZE,
          offset,
        })

        this.loadMoreButtonLoading = false

        if (!result.ok) {
          return
        }

        const scrollY = window.scrollY
        this.posts = [...this.posts, ...result.result.posts]
        await this.nextTick()
        window.scrollTo({top: scrollY})
      },
    },
    computed: {
      cid() {
        return Number(this?.$route?.params?.id)
      },

      postData() {
        return this.posts.map((post) => ({
          ...post,
          date: new Date(post.date || 0),
        }))
      },

      channelTimezone() {
        return this.channel && this.channel.settings && this.channel.settings.timezone_id || 'Etc/GMT'
      },
    },
    beforeRouteEnter(to, from, next) {
      return chain(
        {to, from, store},
        next,
        [
          ({to}) => getPostsByCid({
            cid: Number(to.params.id),
            limit: CHUNK_SIZE,
          }),
        ],
      )
    },
  }
</script>

<style scoped lang="stylus">

</style>

<style lang="stylus">
  .channel__posts-table.el-table--enable-row-hover .el-table__body tr:hover > td
    background-color inherit
</style>
